import { Button, Divider } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  FunctionComponent,
  useState,
  startTransition,
  useEffect,
  isValidElement,
  memo,
  useRef,
} from "react";

import HeaderComponent from "../components/Header";
import MenuComponent from "../components/Menu";
import FooterComponent from "../components/Footer";

// SVG
import SVGBackground from "../assets/undraw_education_f8ru.svg";

import HomePage from "./HomePage";

// SVG
import { getMenuItems } from "../hooks/menuItems";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { IconArrowLeft } from "@tabler/icons-react";

import Lenis from "lenis";

interface MainPageProps {}

const MainPage: FunctionComponent<MainPageProps> = () => {
  const [opened, { toggle, close }] = useDisclosure();

  //Mobile
  const isMobile = useMediaQuery("(max-width: 768px)");

  //Type for menu items
  interface MenuItem {
    category: string;
    options: any[];
  }

  // Lenis - scroll
  const lenis = new Lenis({
    duration: 0.8,
  });

  function raf(time: number) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  // Current menu option
  const [currentMenuOption, setCurrentMenuOption] = useState<string>("Home");

  // Get menu items
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  // Set current component
  const handleSetCurrentComponent = (
    component: JSX.Element | { content: string; edited: any }
  ) => {
    if (isValidElement(component)) {
      startTransition(() => {
        setCurrentComponent(component);
        setObjectComponent({});
      });
    } else {
      setObjectComponent(component as { content: string; edited: any });
      setCurrentComponent(null);
    }
  };

  // Define the type for current component and set the initial state
  const [currentComponent, setCurrentComponent] = useState<JSX.Element | null>(
    <HomePage
      currentMenuOption={currentMenuOption}
      setCurrentMenuOption={setCurrentMenuOption}
      menuItems={menuItems}
      setCurrentComponent={handleSetCurrentComponent}
    />
  );

  // Define the type for object component
  const [objectComponent, setObjectComponent] = useState<{
    content?: string;
    edited?: any;
  }>({});

  // Get menu items from the database
  useEffect(() => {
    getMenuItems()
      .then((snapshot) => {
        setMenuItems(snapshot);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Render home page when menu items are loaded
  useEffect(() => {
    setCurrentComponent(
      <HomePage
        currentMenuOption={currentMenuOption}
        setCurrentMenuOption={setCurrentMenuOption}
        menuItems={menuItems}
        setCurrentComponent={handleSetCurrentComponent}
      />
    );
  }, [menuItems]);

  // Format date
  const formatDate = (
    timestamp: { _seconds: number; _nanoseconds: number } | undefined
  ) => {
    if (!timestamp) return "Invalid Date";
    const date = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
    return format(date, "dd-MM-yyyy");
  };

  // Main container ref
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [footerPosition, setFooterPosition] = useState<number>(-130);

  useEffect(() => {
    if (mainContentRef.current) {
      const height = mainContentRef.current.getBoundingClientRect().height;
      const screenHeight = window.innerHeight;

      if (height > screenHeight) {
        setFooterPosition(-height + screenHeight - 250);
      } else {
        setFooterPosition(-130);
      }
    }
  }, [currentComponent, isMobile]);

  return (
    <div>
      <HeaderComponent menuOpened={opened} toggleMenu={toggle} />

      <div className={`${isMobile ? "p-1" : "mx-52 mt-4 "}`}>
        <div className={`${isMobile ? "p-1" : "grid grid-cols-10 gap-x-5"}`}>
          {/* NavBar */}
          <div
            className={`${isMobile ? "" : "col-span-2 border-r-2 h-screen"}`}
          >
            <MenuComponent
              currentComponent={currentComponent}
              setCurrentComponent={handleSetCurrentComponent}
              menuOpened={opened}
              closeMenu={close}
              menuItems={menuItems}
              currentMenuOption={currentMenuOption}
              setCurrentMenuOption={setCurrentMenuOption}
            />
          </div>

          {/* Main content */}
          <div
            className={`relative ${isMobile ? "" : "col-span-8 mt-4"}`}
            ref={mainContentRef}
          >
            <span
              className={`inset-0 absolute h-screen opacity-15  ${
                isMobile ? "" : "scale-50"
              }`}
              style={{
                backgroundImage: `url(${SVGBackground})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "fixed",
                pointerEvents: "none",
              }}
            ></span>

            <div>
              {currentComponent ? (
                currentComponent
              ) : (
                <span>
                  {isMobile && (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="mt-4"
                    >
                      <Button
                        onClick={() => {
                          setCurrentComponent(
                            <HomePage
                              currentMenuOption={currentMenuOption}
                              setCurrentMenuOption={setCurrentMenuOption}
                              menuItems={menuItems}
                              setCurrentComponent={handleSetCurrentComponent}
                            />
                          );
                          setCurrentMenuOption("Home");
                        }}
                        variant="transparent"
                        leftSection={<IconArrowLeft />}
                      >
                        Powrót do strony głównej
                      </Button>
                    </motion.span>
                  )}

                  <motion.div
                    key={objectComponent.content}
                    initial={{ opacity: 0, x: 10 }}
                    animate={{ opacity: 1, x: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: objectComponent.content || "",
                    }}
                    className={`${isMobile ? "mt-10" : "mt-4"}`}
                  />

                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <Divider
                      className="my-2"
                      label={
                        <>Edytowano dnia {formatDate(objectComponent.edited)}</>
                      }
                    />
                  </motion.span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*  Footer */}
      <div
        className={` ${
          opened
            ? "opacity-0"
            : "opacity-100 transition-all delay-200 duration-150"
        } `}
        style={{
          position: isMobile ? "absolute" : "relative",
          bottom: isMobile ? footerPosition : 0,
          width: "100%",
        }}
      >
        <FooterComponent />
      </div>
    </div>
  );
};

export default memo(MainPage);
