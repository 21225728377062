import { FunctionComponent, Key } from "react";

// DB
import { Paper, Skeleton } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

// Icons
import {
  IconArchive,
  IconBuildingEstate,
  IconCash,
  IconClipboardText,
  IconDatabase,
  IconPaperclip,
  IconSchool,
  IconSectionSign,
  IconSignature,
  IconUsers,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import { getPageContent } from "../hooks/pageContent";

const iconMapping: { [key: string]: React.ComponentType } = {
  IconSchool,
  IconSectionSign,
  IconCash,
  IconUsers,
  IconArchive,
  IconClipboardText,
  IconPaperclip,
  IconDatabase,
  IconBuildingEstate,
  IconSignature,
};

interface MenuItem {
  category: string;
  options: any[];
}

interface PageContent {
  id: string;
  edited: {
    _seconds: number;
    _nanoseconds: number;
  };
  content: string;
}

interface HomePageProps {
  currentMenuOption: string;
  setCurrentMenuOption: (menuOption: string) => void;
  menuItems: MenuItem[];
  setCurrentComponent: (component: JSX.Element | PageContent) => void;
}

const HomePage: FunctionComponent<HomePageProps> = ({
  currentMenuOption,
  setCurrentMenuOption,
  menuItems,
  setCurrentComponent,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Render
  const itemRender = (item: any) => {
    const Icon = iconMapping[item.icon];

    return (
      <Paper
        withBorder
        shadow="sm"
        radius="md"
        className="transition-all duration-150 hover:translate-x-1 cursor-pointer p-2 backdrop-blur-3xl group"
        onClick={async () => {
          const pageContent = await getPageContent(item.content);
          setCurrentComponent(pageContent);
          setCurrentMenuOption(item.label);
        }}
      >
        <div className="flex gap-x-2">
          <div className="flex items-center">
            {item.icon ? <Icon /> : <IconSchool />}
          </div>
          <div>
            <p
              className="font-black"
              style={{
                fontSize: isMobile ? "0.7rem" : "0.9rem",
              }}
            >
              {item.label}
            </p>
            <div
              className="opacity-65"
              style={{
                fontSize: isMobile ? "0.7rem" : "0.8rem",
              }}
            >
              {item.description}
            </div>
          </div>
        </div>
      </Paper>
    );
  };

  // Skeleton render
  const skeletonRender = () => {
    return (
      <Paper withBorder shadow="sm" radius="md" className="">
        <Skeleton height={150} radius="md" className="w-full " />
      </Paper>
    );
  };

  return (
    <div>
      <div className="p-2">
        <div
          className="text-center py-3 font-black font-mono "
          style={{
            fontSize: isMobile ? "1rem" : "1.3rem",
          }}
        >
          {"Zespół Szkół Elektonicznych i Informatycznych w Sosnowcu"
            .split("")
            .map((letter, index) => {
              return (
                <motion.span
                  key={index}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                  initial={{
                    x: 10,
                    opacity: 0,
                  }}
                  transition={{
                    delay: index * 0.01,
                  }}
                >
                  {letter}
                </motion.span>
              );
            })}
        </div>

        {menuItems.length === 0 ? (
          // Skeleton render
          <div
            className={`grid gap-5 ${isMobile ? "grid-cols-2" : "grid-cols-4"}`}
          >
            {Array.from({ length: isMobile ? 8 : 12 }).map((_, index) => {
              return <span key={index}>{skeletonRender()}</span>;
            })}
          </div>
        ) : (
          // Item render
          <>
            {menuItems.map((menuCategory) => {
              return (
                <div
                  key={menuCategory.category}
                  style={{
                    fontSize: isMobile ? "0.7rem" : "1rem",
                  }}
                >
                  <div
                    className="font-black py-4"
                    style={{
                      fontSize: isMobile ? "1rem" : "1.2rem",
                    }}
                  >
                    {menuCategory.category}
                  </div>

                  <div
                    className={` grid ${
                      isMobile ? "grid-cols-2 gap-5" : " grid-cols-5 gap-5"
                    }`}
                  >
                    {menuCategory.options.map(
                      (item: { label: Key | null | undefined }) => {
                        return <span key={item.label}>{itemRender(item)}</span>;
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;
