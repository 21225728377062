import { FunctionComponent, useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Burger, Image, Tooltip } from "@mantine/core";

import ContrastComponent from "./ContrastComponent";

import BipLogo from "../assets/images/bip.svg";
import ZSEIILogo from "../assets/images/zseii_logo.png";

// Desktop Header
const DesktopHeader: FunctionComponent = () => {
  return (
    <div className="grid grid-cols-2 h-full px-1">
      {/* Left */}
      <div className="flex gap-x-4">
        {/* Image */}
        <div className="h-full flex items-center">
          <Tooltip label="Strona BIP" withArrow arrowSize={10}>
            <span>
              <Image
                src={BipLogo}
                alt="header"
                h={80}
                onClick={() => {
                  window.open("https://www.gov.pl/web/bip", "_blank");
                }}
                className="cursor-pointer"
              />
            </span>
          </Tooltip>
        </div>
        {/* Text */}
        <div className="text-3xl pt-2">
          Biuletyn <br /> Informacji Publicznej
        </div>
      </div>

      {/* Right */}
      <div className="flex justify-end gap-x-12">
        <Tooltip
          label="Kliknij by przejść na stronę szkoły"
          withArrow
          arrowSize={10}
        >
          <span
            className="flex h-full gap-x-6 group cursor-pointer select-none"
            onClick={() => {
              window.open("https://zse.edu.pl/", "_blank");
            }}
          >
            {/* Image */}
            <div className="flex items-center group-hover:scale-110 group-hover:rotate-2 transition-all duration-200">
              <Image
                src={ZSEIILogo}
                alt="zseii-logo"
                h={60}
                w="auto"
                fit="contain"
              />
            </div>

            {/* ZSEII */}
            <div className="flex items-center text-xl group-hover:scale-105 group-hover:translate-x-2 transition-all duration-200">
              Zespół Szkół Elektonicznych i Informatycznych w Sosnowcu
            </div>
          </span>
        </Tooltip>

        <div className="">
          <ContrastComponent />
        </div>
      </div>
    </div>
  );
};

// Mobile Header
interface MobileHeaderProps {
  menuOpened: boolean;
  toogleMenu: () => void;
}
const MobileHeader: FunctionComponent<MobileHeaderProps> = ({
  menuOpened,
  toogleMenu,
}) => {
  // Watch for contrast mode
  const [contrastModeEnabled, setContrastModeEnabled] = useState(false);

  useEffect(() => {
    const contrastModeEnabled =
      sessionStorage.getItem("high-contrast") === "true";

    if (contrastModeEnabled) {
      setContrastModeEnabled(true);
    } else {
      setContrastModeEnabled(false);
    }
  }, [menuOpened]);


  return (
    <div className="flex justify-between h-full w-full pe-3  pt-2 ">
      <div className="flex gap-x-1">
        {/* Burger */}
        <div className=" flex items-center px-3">
          <Burger
            opened={menuOpened}
            onClick={toogleMenu}
            aria-label="menu"
            title="menu"
            color={contrastModeEnabled ? "yellow" : "blue"}
          />
        </div>

        {/* BIP logo */}
        <div className="flex items-center ">
          <Image src={BipLogo} alt="header" h={40} />
        </div>
      </div>

      {/* ZSEII */}
      <div
        className="flex items-centerjustify-end "
        onClick={() => {
          window.open("https://zse.edu.pl/", "_blank");
        }}
      >
        <Image src={ZSEIILogo} alt="zseii-logo" h={40} w="auto" fit="contain" />
      </div>
    </div>
  );
};

interface HeaderComponentProps {
  menuOpened: boolean;
  toggleMenu: () => void;
}

const HeaderComponent: FunctionComponent<HeaderComponentProps> = ({
  menuOpened,
  toggleMenu,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="h-full">
      {isMobile ? (
        <MobileHeader menuOpened={menuOpened} toogleMenu={toggleMenu} />
      ) : (
        <DesktopHeader />
      )}
    </div>
  );
};

export default HeaderComponent;
