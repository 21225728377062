import { FunctionComponent } from "react";

// Pages
import MainPage from "./pages/MainPage";

interface AppProps {}

const App: FunctionComponent<AppProps> = () => {
  return (
    <>
      <MainPage />
    </>
  );
};

export default App;
