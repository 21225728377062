import { Button, Drawer, NavLink, ScrollArea } from "@mantine/core";
import { FunctionComponent, lazy, memo, useEffect, useState } from "react";
import { getPageContent } from "../hooks/pageContent";
import {
  IconArchive,
  IconArrowLeft,
  IconBuildingEstate,
  IconCash,
  IconClipboardText,
  IconDatabase,
  IconHome,
  IconPaperclip,
  IconSignature,
  IconUsers,
} from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

// Components
import ContrastComponent from "./ContrastComponent";

// Icons
import { IconSchool, IconSectionSign } from "@tabler/icons-react";
import { motion } from "framer-motion";

const iconMapping: { [key: string]: React.ComponentType } = {
  IconSchool,
  IconSectionSign,
  IconCash,
  IconUsers,
  IconArchive,
  IconClipboardText,
  IconPaperclip,
  IconDatabase,
  IconBuildingEstate,
  IconSignature,
};

const HomePage = lazy(() => import("../pages/HomePage"));

interface MenuComponentProps {
  currentMenuOption: string;
  setCurrentMenuOption: (menuOption: string) => void;
  currentComponent: any;
  setCurrentComponent: (component: any) => void;
  menuOpened: boolean;
  closeMenu: () => void;
  menuItems: {
    category: string;
    options: {
      content: any;
      label: string;
      action: () => void;
      icon?: any;
    }[];
  }[];
}

const MenuComponent: FunctionComponent<MenuComponentProps> = ({
  currentComponent,
  setCurrentComponent,
  menuOpened,
  closeMenu,
  menuItems,
  currentMenuOption,
  setCurrentMenuOption,
}) => {
  // IsMobile
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Animation control
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    if (animate && !isMobile) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [animate, isMobile]);

  const MenuRender = () => {
    return (
      <div>
        <div className="flex justify-center items-center p-3 ">
          <motion.span
            className="group w-full"
            animate={animate ? { y: 0, opacity: 1 } : {}}
            initial={{
              y: 10,
              opacity: animate ? 0 : 1,
            }}
            transition={{
              duration: isMobile ? 0.5 : undefined,
            }}
            layout
            layoutRoot
          >
            <Button
              onClick={() => {
                setCurrentComponent(
                  <HomePage
                    currentMenuOption={currentMenuOption}
                    setCurrentMenuOption={setCurrentMenuOption}
                    menuItems={menuItems}
                    setCurrentComponent={setCurrentComponent}
                  />
                );
                setCurrentMenuOption("Home");
                closeMenu();
              }}
              className="text-2xl font-black"
              leftSection={
                <IconHome className="group-hover:scale-110 transition-all duration-150" />
              }
              variant={currentMenuOption === "Home" ? "filled" : "subtle"}
              color="red"
              fullWidth
            >
              Strona główna
            </Button>
          </motion.span>
        </div>

        <div>
          {menuItems.map((menuCategory) => (
            <div key={menuCategory.category}>
              <NavLink
                label={menuCategory.category}
                childrenOffset={10}
                opened={isMobile ? true : undefined}
                defaultOpened={isMobile ? false : true}
                rightSection={isMobile ? <></> : undefined}
                key={menuCategory.category}
                className="font-black"
                disabled={isMobile ? true : false}
              >
                {menuCategory.options.map((child, index) => {
                  const IconComponent = iconMapping[child.icon];

                  return (
                    <motion.div
                      key={child.label}
                      animate={animate ? { y: 0, opacity: 1 } : {}}
                      initial={{
                        y: 10,
                        opacity: animate ? 0 : 1,
                      }}
                      transition={{
                        delay: index * 0.05,
                        duration: isMobile ? 0.05 : undefined,
                      }}
                      layout
                      style={{
                        translateX: currentMenuOption === child.label ? -10 : 0,
                      }}
                    >
                      <NavLink
                        label={child.label}
                        leftSection={
                          IconComponent ? <IconComponent /> : <IconSchool />
                        }
                        key={child.label}
                        className="hover:-translate-x-1 hover:font-black transition-all duration-75 rounded-xl"
                        onClick={async () => {
                          const pageContent = await getPageContent(
                            child.content
                          );
                          setCurrentComponent(pageContent);
                          setCurrentMenuOption(child.label);
                           closeMenu();
                        }}
                        style={{
                          backgroundColor:
                            currentMenuOption === child.label
                              ? "rgba(0,0,0,0.07)"
                              : "transparent",
                          fontWeight:
                            currentMenuOption === child.label
                              ? "bold"
                              : "normal",

                          borderLeft:
                            currentMenuOption === child.label
                              ? "4px solid #f00"
                              : "none",
                        }}
                      />
                    </motion.div>
                  );
                })}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        <Drawer
          opened={menuOpened}
          onClose={closeMenu}
          position="left"
          withCloseButton={false}
          size="xs"
        >
          <div
            onClick={closeMenu}
            className="flex w-full  items-center gap-x-2 opacity-70 py-1"
          >
            <IconArrowLeft />
            Wróć
          </div>

          <ScrollArea>
            <div className="py-3 opacity-45 flex justify-center items-center">
              Ułatwienia dostępu
              <ContrastComponent />
            </div>

            <MenuRender />
          </ScrollArea>
        </Drawer>
      ) : (
        <div>
          <MenuRender />
        </div>
      )}
    </>
  );
};

export default memo(MenuComponent);
