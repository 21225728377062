import { ActionIcon, Tooltip, useMantineColorScheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconContrastFilled,
  IconMoonFilled,
  IconSunFilled,
  IconTypography,
} from "@tabler/icons-react";
import { FunctionComponent, memo, useEffect } from "react";

interface ContrastComponentProps {}

const ContrastComponent: FunctionComponent<ContrastComponentProps> = () => {
  // IsMobile
  const isMobile = useMediaQuery("(max-width: 768px)");

  //Logic for changing colors
  const { setColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });

  useEffect(() => {
    const highContrastEnabled =
      sessionStorage.getItem("high-contrast") === "true";

    if (highContrastEnabled) {
      document.body.classList.add("high-contrast");
      sessionStorage.setItem("high-contrast", "true");
    } else {
      document.body.classList.remove("high-contrast");
      sessionStorage.setItem("high-contrast", "false");
    }
  }, []);

  const toggleContrast = () => {
    const highContrastEnabled =
      sessionStorage.getItem("high-contrast") === "true";

    if (highContrastEnabled) {
      document.body.classList.remove("high-contrast");
      sessionStorage.setItem("high-contrast", "false");
    } else {
      document.body.classList.add("high-contrast");
      sessionStorage.setItem("high-contrast", "true");
    }
  };

  const turnOffContrast = () => {
    document.body.classList.remove("high-contrast");
    sessionStorage.setItem("high-contrast", "false");
  };

  useEffect(() => {
    const bigFontEnabled = sessionStorage.getItem("big-font") === "true";

    if (bigFontEnabled) {
      document.body.classList.add("big-font");
      sessionStorage.setItem("big-font", "true");
    } else {
      document.body.classList.remove("big-font");
      sessionStorage.setItem("big-font", "false");
    }
  }, []);

  const toggleBigFont = () => {
    const bigFontEnabled = sessionStorage.getItem("big-font") === "true";

    if (bigFontEnabled) {
      document.body.classList.remove("big-font");
      sessionStorage.setItem("big-font", "false");
    } else {
      document.body.classList.add("big-font");
      sessionStorage.setItem("big-font", "true");
    }
  };

  return (
    <div className={`p-1 flex gap-1  ${isMobile ? "flex-row" : "flex-col"}`}>
      {/* Light */}
      <Tooltip label="Tryb jasny" withArrow arrowSize={10} position="left">
        <ActionIcon
          color="cyan"
          variant="transparent"
          radius="xl"
          onClick={() => {
            turnOffContrast();
            setColorScheme("light");
          }}
        >
          <IconSunFilled />
        </ActionIcon>
      </Tooltip>

      {/* Dark */}
      <Tooltip label="Tryb ciemny" withArrow arrowSize={10} position="left">
        <ActionIcon
          color="grey"
          variant="transparent"
          radius="xl"
          onClick={() => {
            turnOffContrast();
            setColorScheme("dark");
          }}
        >
          <IconMoonFilled />
        </ActionIcon>
      </Tooltip>

      {/* High contrast */}
      <Tooltip
        label="Tryb wysokiego kontrastu"
        withArrow
        arrowSize={10}
        position="left"
      >
        <ActionIcon
          color="yellow"
          variant="transparent"
          radius="xl"
          onClick={toggleContrast}
        >
          <IconContrastFilled />
        </ActionIcon>
      </Tooltip>

      {/* Big font */}
      <Tooltip
        label="Tryb dużej czcionki"
        withArrow
        arrowSize={10}
        position="left"
      >
        <ActionIcon
          color="orange"
          variant="transparent"
          radius="xl"
          onClick={toggleBigFont}
        >
          <IconTypography />
        </ActionIcon>
      </Tooltip>
    </div>
  );
};

export default memo(ContrastComponent);
