export const getMenuItems = async () => {
    try {
        const response = await fetch('/data.json');

        if (!response.ok) {
            throw new Error('Failed to fetch menu items');
        }

        const jsonData = await response.json();

        const menuItems = jsonData.menu;

        const categorizedMenu: { category: string, options: any[] }[] = [];

        menuItems.forEach((item: any) => {
            const category = item.category;

            const existingCategory = categorizedMenu.find((cat: any) => cat.category === category);
            if (existingCategory) {
                existingCategory.options.push(item);
            } else {
                categorizedMenu.push({ category, options: [item] });
            }
        });

        return Promise.resolve(categorizedMenu);
    } catch (error) {
        return Promise.reject(error);
    }
}
