export const getPageContent = async (pageId: string) => {
    try {
        const response = await fetch('/data.json');

        if (!response.ok) {
            throw new Error('Failed to fetch page content');
        }

        const jsonData = await response.json();

        const pages = jsonData.pages;

        const pageData = pages.find((page: any) => page.id === pageId);

        if (pageData) {
            return pageData;
        } else {
            return Promise.reject(new Error('Page not found'));
        }
    } catch (error) {
        return Promise.reject(error);
    }
}
