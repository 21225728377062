import { Button, Tooltip } from "@mantine/core";
import { FunctionComponent } from "react";

// Icons
import { IconPhone, IconMap2 } from "@tabler/icons-react";

import { useMediaQuery } from "@mantine/hooks";

interface FooterComponentProps {}

const FooterComponent: FunctionComponent<FooterComponentProps> = () => {
  // IsMobile
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Desktop render
  const desktopRender = () => {
    return (
      <div className="relative w-full h-full bg-gradient-to-tr from-slate-700 to-slate-900 text-white font-mono py-4">
        <div className="h-full grid grid-cols-2">
          {/* Contact */}
          <div className="flex items-center justify-center">
            <div className="text-center">
              <div>
                <Tooltip
                  label="Zadzwoń do szkoły"
                  withArrow
                  arrowSize={15}
                  transitionProps={{
                    duration: 150,
                  }}
                >
                  <span className="group">
                    <Button
                      variant="transparent"
                      color="white"
                      onClick={() => (window.location.href = "tel:+322924470")}
                      leftSection={
                        <IconPhone
                          className="group-hover:rotate-12 transition-all duration-150"
                          color="white"
                          size={16}
                        />
                      }
                      className="group-hover:scale-105 transition-all duration-150"
                      style={{
                        fontSize: "0.7rem",
                      }}
                    >
                      32 292 44 70
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  label="Zadzwoń do szkoły"
                  withArrow
                  arrowSize={15}
                  transitionProps={{
                    duration: 150,
                  }}
                >
                  <span className="group">
                    <Button
                      variant="transparent"
                      color="white"
                      onClick={() => (window.location.href = "tel:513761156")}
                      leftSection={
                        <IconPhone
                          className="group-hover:rotate-12 transition-all duration-150"
                          color="white"
                          size={16}
                        />
                      }
                      className="group-hover:scale-105 transition-all duration-150"
                      style={{
                        fontSize: "0.7rem",
                      }}
                    >
                      513 761 156
                    </Button>
                  </span>
                </Tooltip>
              </div>

              <div
                className="flex items-center justify-center h-full gap-x-3"
                style={{
                  fontSize: "0.7rem",
                }}
              >
                <IconMap2 color="white" size={16} />
                41-200 Sosnowiec, <br /> ul. Jagiellońska 13
              </div>

              <div
                className="opacity-30 select-none pt-1"
                style={{
                  fontSize: "0.8rem",
                }}
              >
                {new Date().getFullYear()} &copy;
              </div>
            </div>
          </div>

          {/* Map */}
          <div className="flex items-center justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2550.3435722412482!2d19.13173557690857!3d50.266843200566484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716dbe890978aa7%3A0x72a04755996b8d9b!2sZesp%C3%B3%C5%82%20Szk%C3%B3%C5%82%20Elektronicznych%20i%20Informatycznych!5e0!3m2!1spl!2spl!4v1726586986717!5m2!1spl!2spl"
              width={"300dvw"}
              height={"100dvw"}
              style={{ border: 0, borderRadius: 10 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Map"
            ></iframe>
          </div>
        </div>
        {/* Author */}
        <div
          className="opacity-30 text-center"
          style={{
            fontSize: "0.5rem",
          }}
        >
          <a href="https://github.com/Vercixxx/">
            Created by Krzysztof Służałek
          </a>
        </div>
      </div>
    );
  };

  // Mobile render
  const mobileRender = () => {
    return (
      <div
        className=" bg-gradient-to-tr from-slate-700 to-slate-900 text-white font-mono pt-2 text-sm"
        style={{
          fontSize: "0.7rem",
        }}
      >
        <div className="flex items-center justify-center">
          <div className="text-center">
            <div>
              <Tooltip
                label="Zadzwoń do szkoły"
                withArrow
                arrowSize={15}
                transitionProps={{
                  duration: 150,
                }}
              >
                <span className="group">
                  <Button
                    variant="transparent"
                    color="white"
                    onClick={() => (window.location.href = "tel:+322924470")}
                    leftSection={
                      <IconPhone
                        className="group-hover:rotate-12 transition-all duration-150"
                        color="white"
                      />
                    }
                    className="group-hover:scale-105 transition-all duration-150"
                    style={{
                      fontSize: "0.7rem",
                    }}
                  >
                    32 292 44 70
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                label="Zadzwoń do szkoły"
                withArrow
                arrowSize={15}
                transitionProps={{
                  duration: 150,
                }}
              >
                <span className="group">
                  <Button
                    variant="transparent"
                    color="white"
                    onClick={() => (window.location.href = "tel:513761156")}
                    leftSection={
                      <IconPhone
                        className="group-hover:rotate-12 transition-all duration-150"
                        color="white"
                      />
                    }
                    className="group-hover:scale-105 transition-all duration-150"
                    style={{
                      fontSize: "0.7rem",
                    }}
                  >
                    513 761 156
                  </Button>
                </span>
              </Tooltip>
            </div>

            <div className="flex items-center justify-center h-full gap-x-3">
              <IconMap2 color="white" />
              41-200 Sosnowiec, <br /> ul. Jagiellońska 13
            </div>

            <div className="opacity-30 select-none">
              {new Date().getFullYear()} &copy;
            </div>
          </div>
        </div>

        {/* Author */}
        <div
          className="opacity-30 text-center"
          style={{
            fontSize: "0.5rem",
          }}
        >
          <a href="https://github.com/Vercixxx/">
            Created by Krzysztof Służałek
          </a>
        </div>
      </div>
    );
  };

  return <>{isMobile ? mobileRender() : desktopRender()}</>;
};

export default FooterComponent;
